import React from "react";


const WhoWeAre = () => {
  return (
    <div className="md:grid md:grid-cols-3 lg:flex-row items-start p-10 lg:p-32">
      <div className="md:col-span-2 lg:pr-8">
        <h2 className="text-3xl font-bold mb-4">About Us</h2>
        <p className="text-gray-500 text-sm md:text-lg leading-relaxed mb-4"  style={{textAlign:'justify'}}>
          Welcome to Birtamod Futsal, the ultimate destination for futsal
          enthusiasts in Birtamode! As a proud locally-owned facility, we offer
          a modern playing surface, top-notch equipment, and a friendly
          environment suited for players of all skill levels. Whether you're
          here to hone your techniques, stay active, or simply enjoy a great
          time with friends, Birtamod Futsal has you covered. Our spacious
          grounds are designed to accommodate both casual players and
          competitive leagues, making it an ideal spot for tournaments, events,
          or just a friendly match. With convenient facilities, ample parking,
          and a vibrant community, we strive to make your experience seamless
          and enjoyable. Our dedicated staff is committed to making every match
          safe, fair, and fun, ensuring that you feel welcome from the moment
          you step onto the pitch. Discover why we’re the leading futsal venue
          in Birtamode, and let us be your home for great games and lasting
          memories. Join us today and be part of the action!
        </p>
      </div>
      {/* <div className="hidden md:block flex-2 grid-cols-2 gap-6 mt-10 pt-16 lg:mt-0">
      


        <iframe width="560" height="315" src="https://www.youtube.com/embed/hWdTb7BdU4I?si=uKXGWr5O80iPALiI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div> */}
      <div className="md:col-span-1 md:pt-16 h-full w-full"> 
      <video
    autoPlay
    muted
    loop
    className="object-cover"
  >
    <source
      src="https://birtamodesportscenter.com/videoplayback.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
      </div>
    </div>
  );
};

export default WhoWeAre;

