import React, { useEffect, useState } from 'react';
import Hero from './components/Homebanner/Hero';
import WhoWeAre from './components/Homebanner/WhoWeAre';
import ContactForm from './components/Homebanner/ContactForm';
import Footer from './components/Homebanner/Footer';
import Navbar from './components/Homebanner/Navbar';
import SignIn from './components/Pages/SignIn';
import SignUp from './components/Pages/SignUp';
import { Route, Router, Routes } from "react-router-dom";
import Popup from './components/Popup';
import background from "./assets/img/background.jpg"
import "./index.css";
import ProtectedRoute from './Protectedroute';
import Userpage from './components/User/Userpage';
import NewsAndUpdates from './components/Homebanner/NewsAndUpdates';

import store from './redux/store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { getUser } from './redux/action';

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        {/* <Router> */}
        <AppMain />
        {/* </Router> */}
      </Provider>
    );
  }
}
function AppMain() {
  const [isPopupVisible, setIsPopupVisible] = useState(true); // Control popup visibility
  const Login = useSelector((state) => state.LoginState);
  const dispatch = useDispatch()

  const handleClosePopup = () => {
    setIsPopupVisible(false); // Close the popup
  };
  useEffect(() => {
    dispatch(getUser())
  }, [])
  return (
    <>
      {isPopupVisible && (
        <Popup
          title="Welcome!"
          content="The Birtamod Sports Center is opening soon. Stay tuned for more."
          imageUrl={background}
          onClose={handleClosePopup} // Pass close handler
        />
      )}
      <Navbar />
      {/* {Login.token} */}
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/about" element={<WhoWeAre />} />
          <Route path="/contactus" element={<ContactForm />} />
          <Route path="/blog" element={<NewsAndUpdates />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/userpage"
            element={
              // <ProtectedRoute>
              // LoginState.token !== null ? children : <Navigate to="/signin" />
              <Userpage />
              // </ProtectedRoute>
            }
          />
        </Routes>
      
      {/* } */}
      <Footer />
    </>
  );
}

// export default App;
