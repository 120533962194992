export const slugs = {
  "new_book": "/new-book",
  "books_details": "/admin/books/",
  "all_books": "/all-books",
  "db_slug_book": "books",

  "new_user": "/new-user",
  "user_details": "/users",
  "all_users": "/all-users",
  "db_slug_user_data": "user_data",
  
  "new_": "/new-",
  "_details": "/",
  "all_": "/all-",
  "db_slug_credit": "credit",

  "new_order": "/new-order",
  "order_details": "/orders",
  "all_orders": "/all-orders",
  "db_slug_orders": "orders",
  
  "new_slots": "/new-slots",
  "slots_details": "/slots",
  "all_slots": "/all-slots",
  "db_slug_slots": "slots",

  "new_coupon": "/new-coupon",
  "coupon_details": "/coupons",
  "all_coupons": "/all-coupons",
  "db_slug_coupons": "coupons",

  "new_team": "/new-team",
  "team_details": "/team",
  "all_team": "/all-team",
  "db_slug_teams": "teams",

  "new_blog": "/new-blogs",
  "blog_details": "/blogs",
  "all_blogs": "/all-blogs",
  
  "new_announcement": "/new-announcement",
  "announcement_details": "/",
  "all_announcement": "/all-announcement",
  
  "new_popup": "/new-popup",
  "popup_details": "/popup",
  "all_popup": "/all-popup",
  "db_slug_blogs": "blogs",
  
  "contact_details": "/contact",
  "all_contact": "/all-contact",
  "db_slug_contact": "contact",
}