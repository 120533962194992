
import React, { useState } from 'react';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { Formik, Form, Field } from 'formik';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { NodePOSTApi } from '../../assets/api';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/action';

function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    // email: Yup.string().email('Invalid email').required('Email is required'),
    username: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });
  const dispatch = useDispatch()

  const LoginHandle = (values) => {
    NodePOSTApi({
      username: values.username,
      password: values.password,
    }, "token/login")
      .then(res => {
        console.log(res.data)
        if (!res.data.error){
        const responseValue = res.data.data.data[0].meta

          dispatch(login(
            `${responseValue.user_id}`,
            `${responseValue.user_name}`,
            `${window.btoa(`${values.username}:${values.password}`)}`,
            `${responseValue.email}`,
            `${responseValue.user_role}`,
            `${responseValue.phone_number}`,
            `${responseValue.phone_number}`,
            ``,
          ))
          navigate('/userpage')

        }
        else
          alert('Sign in error');
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <section>
      <div className="flex items-center justify-center min-h-screen">
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            LoginHandle(values)
            // Fetch users from localStorage
            // const users = JSON.parse(localStorage.getItem("users")) || [];

            // // Check if user exists
            // const user = users.find(user => user.email === values.email && user.password === values.password);

            // if (user) {
            //   // Store login state in local storage
            //   localStorage.setItem("isAuthenticated", "true");
            //   localStorage.setItem("currentUser", JSON.stringify(user)); // Save logged-in user data
            //   navigate('/userpage'); // Redirect to user page
            // } else {
            //   alert("Invalid email or password!");
            // }
          }}
        >
          {({ errors, touched }) => (
            <Form className="bg-white shadow-2xl rounded px-8 pt-1 pb-8 mb-4">
              <div className='text-[32px] text-center font-medium'>Sign In</div>
              <div className="mb-4">
                <Field
                  name="username"
                  type="username"
                  placeholder="UserName"
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.email && touched.email ? 'border-red-500' : ''}`}
                />
                {/* {errors.email && touched.email && <div className="text-red-500 text-sm mt-1">{errors.email}</div>} */}
              </div>
              <div className="mb-6">
                <div className="flex relative items-center justify-center">
                  <Field
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline ${errors.password && touched.password ? 'border-red-500' : ''}`}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 flex items-center px-2 py-2 focus:outline-none"
                  >
                    {showPassword ? (
                      <IoEyeOutline className='mt-[-15px] text-slate-500' />
                    ) : (
                      <IoEyeOffOutline className='mt-[-15px] text-slate-500' />
                    )}
                  </button>
                </div>
                {errors.password && touched.password && <div className="text-red-500 text-sm mt-1">{errors.password}</div>}
              </div>
              <button
                type="submit"
                className="bg-primary rounded-full duration-150 bg-sky-800 hover:bg-green-700 w-full text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
              >
                Log In
              </button>
              <p className="text-sm mt-4">
                Don't have an account?{' '}
                <Link to="/signup" className="inline-block align-baseline font-bold text-sm">
                  Register
                </Link>
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default SignIn;
