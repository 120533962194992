

import React from "react";
import CustomCarousel from "./customslider";

export default function Slider() {
  const images = [
    {
      imgURL:
        "https://images.pexels.com/photos/114296/pexels-photo-114296.jpeg",
      imgAlt: "img-1",
      title: "Play Futsal",
      description: "Registrations Now Open!",
      link: "/signin",
    },
    {
      imgURL:
        "https://images.pexels.com/photos/16499021/pexels-photo-16499021/free-photo-of-soccer-ball-lying-on-the-grass.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      imgAlt: "img-2",
      title: "Futsal Festival 2024",
      description: "Registrations Now Open!",
      link: "/signin",
    },
    {
      imgURL:
        "https://images.pexels.com/photos/24589151/pexels-photo-24589151/free-photo-of-man-playing-soccer-on-field.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      imgAlt: "img-3",
      title: "Select Team Tryouts",
      description: "Apply Today!",
      link: "/signin",
    },
    {
      imgURL:
        "https://images.pexels.com/photos/16499021/pexels-photo-16499021/free-photo-of-soccer-ball-lying-on-the-grass.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      imgAlt: "img-4",
      title: "Futsal Tournament 2025",
      description: "Registrations Now Open!",
      link: "/signin",
    },
  ];

  return (
    <div className="flex items-center justify-center">
      <CustomCarousel>
        {images.map((image, index) => (
          <a
            key={index}
            href={image.link}
            target="_blank"
            rel="noopener noreferrer"
            className="relative group"
          >
            {/* Image */}
            <img
              src={image.imgURL}
              alt={image.imgAlt}
              className="w-full object-cover rounded-2xl"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-80 group-hover:opacity-130 transition-opacity">
              <div>
              <h1 className="text-white md:text-3xl text-2xl font-bold">{image.title}</h1>
              <h4 className="text-white md:text-2xl text-xl font-medium">{image.description}</h4>
              </div>
            </div>
          </a>
        ))}
      </CustomCarousel>
    </div>
  );
}
