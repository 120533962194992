

import React from "react";

import WhoWeAre from "./WhoWeAre";

import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";

import NewsAndUpdates from "./NewsAndUpdates";
import Booking from "./Booking";

import TestimonialsCarousel from "./Testimonials";
import Banner from "./Banner";
import { useSelector } from "react-redux";



const Hero = () => {
  const LoginState = useSelector((state) => state.LoginState);
  return (
    <section>

      <div className="relative w-full md:h-screen h-[50vh]">
        {/* Video Element */}
        <video
          autoPlay
          muted
          loop
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source
            src="https://birtamodesportscenter.com/videoplayback.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        {/* Overlay Content */}
        <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-white">
          <h1 className="text-2xl md:text-6xl font-bold mb-6 text-center">
            Welcome to Birtamod Sports Center!
          </h1>
          <h6 className="text-center text-lg mb-10">
            Welcome to Birtamod Futsal, the ultimate destination for futsal enthusiasts in Birtamode!
          </h6>
          {LoginState.token == null ?

            <Link to="/signup">
              <button className="bg-transparent text-white border border-yellow-500 px-4 py-2 rounded hover:bg-slate-700 text-lg md:text-xl">
                Sign Up
              </button>
            </Link>
            :
            <Link
              to="/userpage"
            >
              <button className="bg-transparent text-white border border-yellow-500 px-4 py-2 rounded hover:bg-slate-700 text-lg md:text-xl">
                Dashboard
              </button>
            </Link>
          }
        </div>
      </div>


      <NewsAndUpdates />
      <WhoWeAre />
      <Booking />
      <Banner />
      <TestimonialsCarousel />
      <ContactForm />

    </section>

  );
};

export default Hero;
