import React, { useEffect } from "react";
import Matches from "./Matches";
import MatchBooking from "./Userbooking";
import { useSelector } from "react-redux";
import { slugs } from "../../assets/slugs";
import { AdsGETApiAuth } from "../../assets/api";


const Dashboard = () => {
	const Login = useSelector((state) => state.LoginState);
	useEffect(() => {
		window.scrollTo(0, 0);
		GetAllUser()
	}, [])
	const [userData, setuserData] = React.useState({ date: "" });

	const GetAllUser = () => {
		AdsGETApiAuth({
			per_page: 100,
			"filter[meta_query][2][key]": "user_id",
			"filter[meta_query][2][value][0]": `${Login.userID}`,
			"filter[meta_query][2][compare]": "=",
		}, slugs.db_slug_user_data)
			.then((res) => {
				if (res.data.length !== 0)
					setuserData(res.data[0]);
			})
			.catch(err => {
				console.log(err)
			}).finally(() => {
			})
	}

	return (
		<div className="min-h-screen bg-gray-100">
			<div className="">
				{/* Navbar */}
				{/* <nav className="bg-blue-900 text-white px-6 py-4">
                <div className=" flex gap-14 items-center justify-end px-32">
                    <Link to="/">
                <div className="text-lg font-semibold">Home</div>
                </Link>
                <div className="text-lg font-semibold">Teams</div>
                </div>
            </nav> */}

				{/* Announcement Banner */}
				<div className="bg-green-600 text-white md:px-6 py-4">
					<p className="md:font-medium md:px-28 text-sm px-5">
						<span className="md:font-bold">Announcement!</span> If you need to cancel a booked match, please inform us promptly at [9852620658]. Delayed notifications may result in your accumulated match count for free bonus matches being reset to zero. Thank you for supporting our Sports Hub!
					</p>

				</div>
			</div>


			{/* Main Dashboard */}
			<div className="max-w-4xl mx-auto mt-6 bg-white rounded-lg shadow p-6">
				{/* Welcome Section */}
				<div className="text-lg font-medium mb-4">
					Welcome, <span className="text-blue-600 font-bold">{Login.userName}</span>
				</div>


				{/* Balance */}
				{/* <div className="flex justify-between items-center mb-6">
                    <div className="text-sm font-semibold">Balance: </div>
                    <div className="bg-green-200 text-green-800 font-bold px-3 py-1 rounded-full">
                        Rs. 0
                    </div>
                </div> */}

				{/* Info Boxes */}
				<div className="grid grid-cols-2 gap-4">
					{/* Played So Far */}
					<div className="bg-blue-800 text-white p-4 rounded shadow">
						<p className="text-sm font-medium">Played So Far</p>
						<p className="text-xs mt-2">
							Scroll to bottom to see the list. Matches
						</p>
					</div>

					{/* Joined On */}
					<div className="bg-blue-800 text-white p-4 rounded shadow">
						<p className="text-sm font-medium">Joined On</p>
						<p className="text-lg mt-2 font-bold">{userData.date.slice(0, 10)}</p>
					</div>
				</div>
				{/* Free Bonus Booking Info */}
				<div className="mt-6 bg-gray-100 border-l-4 border-blue-800 p-4">
					<p className="text-sm font-medium">
						To get a free bonus booking, you need to play{" "}
						<span className="text-blue-600 font-bold">10 matches</span>.
					</p>
				</div>
			</div>
			<MatchBooking />
			<Matches />
		</div>
	);
};

export default Dashboard;
