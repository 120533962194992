import React, { useState } from "react";

const testimonials = [
  {
    quote: "The facility is good. It's the best futsal I've ever played in Birtamode.",
    author: "Fan Young",
  },
  {
    quote: "Amazing atmosphere and top-notch facilities. Highly recommend!",
    author: "John Doe",
  },
  {
    quote: "Friendly staff and well-maintained grounds. A great place to enjoy futsal.",
    author: "Jane Smith",
  },
];

const TestimonialsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (currentIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="bg-gray-50 py-12">
      <div className="text-center max-w-4xl mx-auto">
        <h2 className="text-blue-900 font-bold uppercase text-sm mb-2">
          Testimonials
        </h2>
        <h3 className="text-2xl font-semibold mb-4">What Customers Say?</h3>
        <div className="relative border-2 p-4 py-5 rounded-3xl shadow-xl">
          {/* Carousel Content */}
          <div className="text-center">
            <p className="text-gray-700 italic text-lg">
              "{testimonials[currentIndex].quote}"
            </p>
            <p className="text-gray-900 font-bold mt-4">
              - {testimonials[currentIndex].author}
            </p>
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center mt-6">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-3 h-3 rounded-full mx-1 ${
                  index === currentIndex
                    ? "bg-blue-600"
                    : "bg-gray-300"
                }`}
              ></button>
            ))}
          </div>

          {/* Previous and Next Buttons */}
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2">
            <button
              onClick={handlePrev}
              className="text-blue-600 text-5xl font-bold"
            >
              {/* &larr; */}
              {"<"}
            </button>
          </div>
          <div className="absolute top-1/2 right-4 transform -translate-y-1/2">
            <button
              onClick={handleNext}
              className="text-blue-600 text-5xl font-bold"
            >
              {/* &rarr; */}
              {">"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
