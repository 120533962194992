import React from "react";
import logo from "../../assets/img/birtamod.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { logout } from "../../redux/action";
  const Navbar = () => {
  const LoginState = useSelector((state) => state.LoginState);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-t from-[rgb(0,41,89)] to-sky-500 flex flex-col justify-between">
      <header className="md:flex justify-between items-center px-12 py-4 border-b-2 border-white md:gap-4 md:pb-2">
        <div className="flex items-center justify-center">
          <Link
            to="/">
            <img src={logo} alt="Logo" className="h-10 md:h-20" />
          </Link>
        </div>
        <nav className="flex md:gap-24 items-center justify-center gap-4">
          <Link
            to="/"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white text-sm hover:underline md:text-lg">
              Home
            </a>
          </Link>
          <Link
            to="/about"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white text-sm hover:underline md:text-lg">
              About Us
            </a>
          </Link>
          <Link
            to="/blog"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white text-sm hover:underline md:text-lg">
              Blogs
            </a>
          </Link>
          <Link
            to="/popup"
            className={({ isActive }) =>
              isActive ? "text-blue-500" : "text-slate-500"
            }
          >
            <a className="text-white text-sm hover:underline md:text-lg">
              Live
            </a>
          </Link>
          {LoginState.token == null ?
            <Link
              to="/signin"
              className={({ isActive }) =>
                isActive ? "text-blue-500" : "text-slate-500"
              }
            >
              <a className="text-white border-s-indigo-800 text-sm hover:underline md:text-lg">
                Login
              </a>
            </Link>
            :
            <div className="flex gap-5 items-center">
              <Link
                to="/userpage"
                className={({ isActive }) =>
                  isActive ? "text-blue-500" : "text-slate-500"
                }
              >
                <a className="text-white border-s-indigo-800 text-sm hover:underline md:text-lg">
                  {LoginState.userName}
                </a>
              </Link>
              <div onClick={() => {
                dispatch(logout())
                navigate("/")
              }}>
                <MdLogout className="text-white" />
              </div>
            </div>
          }
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
