import React from 'react';
import bannerbg from "../../assets/img/bannerbg.jpg";

const Banner = () => {
    return (
        <div>
            <div
                className="relative bg-cover h-[350px] text-white"
                style={{ backgroundImage: `url(${bannerbg})` }}
            >
                <div className="absolute inset-0 px-2">
                    <div className="max-w-4xl mx-auto text-black shadow-gray-200 font-extrabold">
                        <h1 className="text-3xl mb-4 text-center pt-16">
                        We’re not just a place to play — we’re where the game comes alive.
                        </h1>
                        <h2 className="text-xl mb-4 text-center pt-5"> Birtamod Futsal is for those who crave more than just competition; Don’t just watch the game — own it. Let’s go!</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner