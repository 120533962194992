import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../assets/api";
import { slugs } from "../../assets/slugs";
import { useSelector } from "react-redux";

const Matches = () => {
  const [pendingMatches, setPendingMatches] = useState([]);
  const [pastMatches, setPastMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const Login = useSelector((state) => state.LoginState);

  // Fetch data from the API
  useEffect(() => {
    GetAllMatches()
  }, []);

  const GetAllMatches = () => {
    setIsLoading(true)
    AdsGETApiAuth({
      per_page: 100,
      "filter[meta_query][2][key]": "user_id",
      "filter[meta_query][2][value][0]": `${Login.userID}`,
      "filter[meta_query][2][compare]": "=",
    }, slugs.db_slug_orders)
      .then((res) => {
        var allOrders = res.data
        setPendingMatches(allOrders.filter(a => a.meta.status == "Pending"));
        setPastMatches(allOrders.filter(a => a.meta.status == "Booked"));
        setIsLoading(false)

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
      })
  }

  // Columns for Pending Matches Table
  const pendingColumns = [
    {
      name: "SN",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
    },
    { name: "Booked Date", selector: (row) => row.meta.date_booking, sortable: true },
    { name: "Match Date", selector: (row) => row.date.slice(0, 10), sortable: true },
    { name: "Match Time", selector: (row) => row.meta.time },
    { name: "Amount", selector: (row) => row.meta.price, sortable: true },
    // { name: "Check-In Code", selector: (row) => row.checkInCode },
    // { name: "Time Left", selector: (row) => row.timeLeft },
    // {
    //   name: "Actions",
    //   cell: (row) => (
    //     <button className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700">
    //       Cancel
    //     </button>
    //   ),
    // },
    // { name: "Status", selector: (row) => row.status },
  ];

  // Columns for Past Matches Table
  const pastColumns = [
    {
      name: "Sn",
      selector: (_, index) => index + 1,
      maxWidth: "50px",
    },
    { name: "Booked Date", selector: (row) => row.meta.date_booking, sortable: true },
    { name: "Match Date", selector: (row) => row.date.slice(0, 10), sortable: true },
    { name: "Match Time", selector: (row) => row.meta.time },
    { name: "Amount", selector: (row) => row.meta.price, sortable: true },
  ];

  return (
    <div className="p-6 bg-gray-100">
      {!isLoading &&
        <div className="max-w-5xl mx-auto space-y-8">
          {/* Pending Matches */}

          <div className="bg-white shadow-md rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-4">Pending Matches</h2>
            <DataTable
              columns={pendingColumns}
              data={pendingMatches}
              progressPending={isLoading}
              noDataComponent={
                <div className="text-red-500 font-semibold">
                  You haven't booked any game currently!
                </div>
              }
              pagination
              highlightOnHover
            />
          </div>

          {/* Past Matches */}
          <div className="bg-white shadow-md rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-4">Past Matches</h2>
            <DataTable
              columns={pastColumns}
              data={pastMatches}
              progressPending={isLoading}
              noDataComponent={
                <div className="text-red-500 font-semibold">
                  You haven't played any game yet!
                </div>
              }
              pagination
              highlightOnHover
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Matches;

