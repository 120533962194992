


import React, { useState, useEffect } from "react";

function CustomCarousel({ children }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slideNext();
          setSlideDone(true);
        }, 5000)
      );
    }
  }, [slideDone]);

  const slideNext = () => {
    setActiveIndex((val) => (val >= children.length - 1 ? 0 : val + 1));
  };

  const slidePrev = () => {
    setActiveIndex((val) => (val <= 0 ? children.length - 1 : val - 1));
  };

  const AutoPlayStop = () => {
    if (timeID) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <div
      className="relative md:w-1/2 overflow-hidden bg-black rounded-2xl"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
    >
      <div
        className="flex transition-transform duration-1000"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {children.map((item, index) => (
          <div key={index} className="w-full flex-shrink-0">
            {item}
          </div>
        ))}
      </div>

      <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {children.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full border transition-colors ${
              activeIndex === index ? "bg-black" : "bg-white"
            }`}
            onClick={() => setActiveIndex(index)}
          ></button>
        ))}
      </div>

      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white text-2xl"
        onClick={slidePrev}
      >
        {"<"}
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white text-2xl"
        onClick={slideNext}
      >
        {">"}
      </button>
    </div>
  );
}

export default CustomCarousel;
