import React, { useEffect } from "react";
import Slider from "../Slider/Slider";
import Blog from "./Newslist";
import { AdsGETApi } from "../../assets/api";
import { slugs } from "../../assets/slugs";

const NewsAndUpdates = () => {
  const newsItems = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/114296/pexels-photo-114296.jpeg", // Replace with the actual image path or URL
      title: "Futsal Festival 2024",
      description: "Registrations Now Open!",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/26955012/pexels-photo-26955012/free-photo-of-football-ball-lying-in-goal-net.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", // Replace with the actual image path or URL
      title: "Select Team Tryouts",
      description: "Apply Today!",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/24589151/pexels-photo-24589151/free-photo-of-man-playing-soccer-on-field.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", // Replace with the actual image path or URL
      title: "New Gear is Coming!",
      description: "Pre-orders opening soon!",
    },
  ];

  const [news, setNews] = React.useState([])
  useEffect(() => {
    AdsGETApi({
      per_page: 100,
      "filter[meta_query][1][key]": "type",
      "filter[meta_query][1][value][0]": "Blogs",
      "filter[meta_query][1][compare]": "=",
    }, slugs.db_slug_blogs)
      .then((res) => {
        setNews(res.data)
      })
  }, [])

  return (
    <section className="container mx-auto p-6">
      <div className="border-2 rounded-2xl bg-blue-100 shadow-2xl py-10 px-3">
        <h2 className="md:text-3xl text-2xl font-bold text-blue-800 text-center mb-10">
          NEWS AND UPDATES
        </h2>
        <Slider />
      </div>
      <Blog />

    </section>
  );
};

export default NewsAndUpdates;
