import React, { useState, useEffect } from "react";
import axios from "axios";
import heroImage from "../../assets/img/background.jpg";
import { AdsGETApiAuth, AdsPOSTApi, NodeGETApi } from "../../assets/api";
import { slugs } from "../../assets/slugs";
import { useSelector } from "react-redux";

const MatchBooking = () => {
  const [slotsData, setSlotsData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [message, setMessage] = useState("");
  const [reload, setReload] = React.useState(false)
  const [Orders, setOrders] = React.useState([])
  const [SlotsArray, setSlotsArray] = React.useState([])
  const [DateBooking, setDateBooking] = React.useState("")

  // Fetch slots data
  useEffect(() => {
    GetAllSlots()
    GetAllOrders()
    }, []);

  // Handle date change
  const handleDateChange = (event) => {
    const date = event.target.value;
    setDateBooking(date)
    setSelectedDate("");
    setTimeout(() => {
      setSelectedDate(date);

    }, 200)
    setSlots(slotsData[date] || []);
  };

  // Handle booking
  const GetAllSlots = () => {
    AdsGETApiAuth({
      per_page: 4900,
      _fields: "id,slug,meta,title"
    }, slugs.db_slug_slots)
      .then((res) => {
        setSlotsData(res.data)
        const today = new Date().toISOString().split("T")[0];
        setSelectedDate(today);
        setSlots(res.data[today] || []);
      })
      .catch(err => {
      })
  }
  const GetAllOrders = () => {
    setReload(false)
    NodeGETApi({ DateSelected: selectedDate }, "token/date-order")
      .then((res) => {
        setOrders(res.data.orders)
      })
      .catch(err => {
      }).finally(() => {
        setReload(true)
      })
  }


  return (
    <div className="md:min-h-screen bg-gray-100 mb-5">
      <div
        className="relative bg-cover h-[700px] md:h-[728px] text-white"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,41,83,1)0%] to-[rgba(0, 45, 91, 0.8) 80%]">
          <div className="max-w-4xl mx-auto text-center pt-7">
            <h1 className="text-3xl font-bold py-4">Book a Football Match</h1>
            <p className="text-lg mb-6">Select your preferred date and slot to book.</p>

            {/* Date Selector */}
            <input
              type="date"
              value={selectedDate || ""}
              onChange={handleDateChange}
              min={new Date().toISOString().split("T")[0]} // Set minimum date to today
              max={new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]} // Set max date to 7 days from today
              className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-300 text-gray-500"
            />

            {/* Slots Display */}
            {/* {JSON.stringify(slotsData)} */}
            {/* {slotsData.length !== 0 ? (
                slotsData.map((slot, index) => (
                  <section key={index}> */}

            {/* // <div
                  //   key={index}
                  //   onClick={() => !slot.booked && setSelectedSlot(slot)}
                  //   className={`md:p-4 p-1 border rounded-lg text-center shadow-sm cursor-pointer ${slot.booked
                  //     ? "bg-red-100 text-red-700 cursor-not-allowed"
                  //     : selectedSlot?.time === slot.time
                  //       ? "bg-blue-200 text-blue-800"
                  //       : "bg-green-100 text-green-700"
                  //     }`}
                  // >
                  //   <div className="font-bold">{slot.time}</div>
                  //   <div>Rs {slot.price}</div>
                  //   <div>
                  //     {slot.booked ? (
                  //       <span className="text-red-600 font-semibold">Booked</span>
                  //     ) : (
                  //       <span className="text-green-600 font-semibold">Available</span>
                  //     )}
                  //   </div>
                  // </div> */}
            {selectedDate !== "" &&
              <SlotModalView DateBooking={selectedDate} setSelectedDate={setSelectedDate} />
            }
            {/* </section>
            ))
            ) : ( */}
            {/* <div className="text-gray-300 col-span-full">Select a date for booking.</div>
              )} */}
            {/* </div> */}

            {/* Booking Button */}


            {/* Message Display */}
            {message && (
              <p className="mt-4 text-lg font-semibold text-center text-gray-700">
                {message}
              </p>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};

export default MatchBooking;



const SlotModalView = ({
  DateBooking, setSelectedDate
}) => {
  const Login = useSelector((state) => state.LoginState);

  const [reload, setReload] = React.useState(false)
  const [Projects1, setProjects1] = React.useState([])
  const [Orders, setOrder] = React.useState([])

  const [SlotID, setSlotID] = React.useState("")
  const [SlotType, setSlotType] = React.useState("")
  const [SlotName, setSlotName] = React.useState("")
  const [SlotModal, setSlotModal] = React.useState(false)
  // const [DateBooking, setDateBooking] = React.useState(FetchData.meta.date_booking)
  const [TimeBooking, setTimeBooking] = React.useState("")
  const [Price, setPrice] = React.useState("")

  const handleBooking = () => {
    const data = {
      "title": "",
      "status": "publish",
      "meta": {
        "user_id": `${Login.userID}`,
        "user_name": `${Login.userName}`,
        "phone_number": `${Login.userPhoneNumber}`,
        "slot_name": `${SlotName}`,
        "slot_id": `${SlotID}`,
        "slot_type": `${SlotType}`,
        "date_booking": `${DateBooking}`,
        "time": `${TimeBooking}`,
        "status": `Pending`,
        "price": `${Price}`,
        "discount": "",
        "coupon": "",
        "order_history": ""
      }
    }
    AdsPOSTApi(data, slugs.db_slug_orders)
      .then((res) => {
        // setReload(true)
        alert('Booking Successful. Please Contact Admin');
        setSelectedDate("")
        // setID(res.data.id)
        // setAlertShow(true)
        // setAlertcomp("Added Successfully")
        // history.push(`${slugs.order_details}/${res.data.slug}`)
      }).catch(err => {
        setReload(false)
        console.log(err)
        alert('Booking Error. Please Contact Admin');
        // setAlertShow(true)
        // setAlertcomp(err.response.data.message)
      })
  };

  const GetAllProjects = (params, slug) => {
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        setProjects1(res.data)
      })
      .catch(err => {
      })
  }
  const GetAllNode = (params, slug) => {
    setReload(false)
    NodeGETApi(params, slug)
      .then((res) => {
        setOrder(res.data.orders)
      })
      .catch(err => {
      }).finally(() => {
        setReload(true)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 4900,
      _fields: "id,slug,meta,title",
      order: "asc"
    }, slugs.db_slug_slots)
    GetAllNode({
      DateSelected: DateBooking
    }, "token/date-order")

  }, [])
  return (
    <div>
      {Projects1.length !== 0 && reload &&
        <div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 overflow-y-scroll max-h-[370px] md:max-h-full mb-6"
            style={{ marginTop: "10px" }}
          >
            {/* {DateBooking} */}
            {Projects1.map((item, index) => (
              <section key={index}
                className={` text-center shadow-sm cursor-pointer }`}
              >
                <SlotBooking
                  Orders={Orders} setSlotName={setSlotName}
                  setPrice={setPrice} setTimeBooking={setTimeBooking}
                  TimeBooking={TimeBooking}
                  setSlotID={setSlotID} setSlotModal={setSlotModal}
                  SlotID={SlotID} item={item}
                  setSlotType={setSlotType} SlotType={SlotType}
                  Projects1={Projects1}
                  DateBooking={DateBooking}
                />
              </section>
            ))}
          </div>
          <button
            onClick={handleBooking}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 disabled:bg-gray-500 disabled:cursor-not-allowed"
            // disabled={!selectedSlot || slotsData[selectedDate]?.some((s) => s.time === selectedSlot?.time && s.booked)}
            disabled={SlotID == ""}
          >
            Confirm Booking
          </button>
        </div>
      }

    </div>
  )
}


const SlotBooking = ({
  Orders, setSlotName, setPrice,
  setTimeBooking, setSlotID, setSlotModal, SlotID, item,
  setSlotType, DateBooking
}) => {

  const [Available, setAvailable] = React.useState(true)
  const [ThisOrder, setThisOrder] = React.useState(false)
  const [ThisBooked, setThisBooked] = React.useState("Available")
  var searchOrder = Orders.filter(
    a => a.meta.slot_id == item.id
  )
  useEffect(() => {
    var searchOrder = Orders.filter(
      a => a.meta.slot_id == item.id
    )
    if (searchOrder.length !== 0) {
      setAvailable(false)
      setThisBooked("Booked")
      // console.log(searchOrder[0].id, ID, item.title.rendered)
      // if (searchOrder[0].id == ID) {
      //   setThisOrder(true)
      // }
    }
    else {
      // Orders
      var dateOrder = Orders.filter(a => a.meta.date_booking == DateBooking)
      var a = dateOrder.filter(a => a.meta.time == item.meta.time);
      console.log(a, item.title.rendered)
      if (a.length !== 0) {
        var A7check = a.filter(a => a.meta.slot_type == "7A")
        if (A7check.length !== 0) {
          setAvailable(false)
          setThisBooked("Unavailable")
        }
        var A5check = a.filter(a => a.meta.slot_type == "5A")
        if (A5check.length !== 0 && item.meta.type == "7A") {
          setAvailable(false)
          setThisBooked("Unavailable")
        }
      }
    }
  }, [])
  var SearchSuccess = () => {
    setSlotName(item.title.rendered)
    setPrice(item.meta.price)
    setTimeBooking(item.meta.time)
    setSlotType(item.meta.type)
    setSlotID(item.id)
    setSlotModal(false)
  }
  return (
    <div
      onClick={() => {
        if (searchOrder.length == 0) {
          SearchSuccess()
        }
        else {
          if (ThisOrder) {
            SearchSuccess()
          }
        }
      }}
    >
      {DateBooking}
      <div style={{
        padding: 10, border: "1px solid #000", margin: 3, borderRadius: 10,
        backgroundColor:
          !Available ? ThisOrder ? "#8aeda9" : "#f00" :
            (SlotID == item.id) ? "#fff" : "#8aeda9"
      }}>
        <div className="row">
          <div className="col-8" style={{
            color:
              Available ? "#000" : ThisOrder ? "#000" : "#fff", fontWeight: "bold"
          }}>
            {item.title.rendered}
          </div>
          <div className="col-4" style={{ color: Available ? "#000" : ThisOrder ? "#000" : "#fff", fontWeight: "bold" }}>
            {ThisBooked}
          </div>
        </div>
      </div>
    </div>
  )
}

