

// import React, { useState, useEffect } from "react";
// import heroImage from "../../assets/img/background.jpg";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { AdsGETApi, NodeGETApi } from "../../assets/api";
// import { slugs } from "../../assets/slugs";

// const Booking = () => {
//   const Login = useSelector((state) => state.LoginState);
//   const navigate = useNavigate();
//   const [slotsData, setSlotsData] = useState({});
//   const [selectedDate, setSelectedDate] = useState("");
//   const [slots, setSlots] = useState([]);
//   const [selectedSlot, setSelectedSlot] = useState(null);
//   const [message, setMessage] = useState("");
//   const [reload, setReload] = React.useState(false)
//   const [Orders, setOrders] = React.useState([])
//   const [SlotsArray, setSlotsArray] = React.useState([])
//   const [DateBooking, setDateBooking] = React.useState("")

//   // Get the current date in YYYY-MM-DD format
//   const getCurrentDate = () => {
//     const today = new Date();
//     const year = today.getFullYear();
//     const month = String(today.getMonth() + 1).padStart(2, "0");
//     const day = String(today.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

//   // Fetch slots data from the API
//   useEffect(() => {
//     // axios
//     //   .get("https://app.birtamodesportscenter.com/token/slots")
//     //   .then((response) => {
//     //     const currentDate = getCurrentDate();
//     //     setSelectedDate(currentDate); // Set default selected date
//     //     const formattedSlots = response.data.slots; // Extract the slots array
//     //     setSlotsData(formattedSlots); // Store slots in state
//     //   })
//     //   .catch((error) => console.error("Error fetching slots:", error));
//     GetAllSlots()
//     GetAllOrders()
//   }, []);

//   const GetAllSlots = () => {
//     AdsGETApi({
//       per_page: 4900,
//       _fields: "id,slug,meta,title"
//     }, slugs.db_slug_slots)
//       .then((res) => {
//         setSlotsData(res.data)
//         const today = new Date().toISOString().split("T")[0];
//         setSelectedDate(today);
//         setSlots(res.data[today] || []);
//       })
//       .catch(err => {
//       })
//   }
//   const GetAllOrders = () => {
//     setReload(false)
//     NodeGETApi({ DateSelected: selectedDate }, "token/date-order")
//       .then((res) => {
//         setOrders(res.data.orders)
//       })
//       .catch(err => {
//       }).finally(() => {
//         setReload(true)
//       })
//   }

//   // // Filter slots for the selected date
//   // const filteredSlots = slotsData.filter(
//   //   (slot) => slot.meta.date === selectedDate || slot.meta.date === "undefined"
//   // );

//   // // Handle date selection
//   // const handleDateChange = (event) => {
//   //   const date = event.target.value;
//   //   setSelectedDate(date);
//   // };

//   const handleDateChange = (event) => {
//     const date = event.target.value;
//     setDateBooking(date)
//     setSelectedDate("");
//     setTimeout(() => {
//       setSelectedDate(date);

//     }, 200)
//     setSlots(slotsData[date] || []);
//   };

//   return (
//     <div className="md:min-h-screen p-6 bg-gray-100">
//       <div
//         className="relative bg-cover h-[600px] md:h-[728px] text-white"
//         style={{ backgroundImage: `url(${heroImage})` }}
//       >
//         <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,41,83,1)0%] to-[rgba(0, 45, 91, 0.8) 80%]">
//           <div className="max-w-4xl mx-auto">
//             <h1 className="text-2xl font-bold mb-4 text-center pt-16">
//               Football Pitch Booking
//             </h1>

//             {/* Date Selector */}
//             <div className="mb-6 text-center">
//               <input
//                 type="date"
//                 value={selectedDate || ""}
//                 onChange={handleDateChange}
//                 min={new Date().toISOString().split("T")[0]} // Set minimum date to today
//                 max={new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]} // Set max date to 7 days from today
//                 className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-300 text-slate-500"
//               />
//             </div>

//             {/* Selected Date */}
//             {selectedDate && (
//               <h2 className="md:text-xl text-sm md:font-semibold text-center md:mb-4">
//                 Selected Date: {selectedDate}
//               </h2>
//             )}

//             {selectedDate !== "" &&
//               <SlotModalView DateBooking={selectedDate} setSelectedDate={setSelectedDate} />
//             }

//             Slots Display
//             <div className="grid grid-cols-2 md:grid-cols-3 gap-4 overflow-y-scroll max-h-[345px] md:max-h-96 px-3">
//               {filteredSlots.length > 0 ? (
//                 filteredSlots.map((slot) => (
//                   <div
//                     key={slot.id}
//                     className={`p-2 md:p-4 border rounded-lg text-center shadow-sm bg-green-100 text-black`}
//                     // ${
//                     // slot.booked
//                     // ? "bg-red-100 text-red-700"
//                     // : "bg-green-100 text-green-700"
//                     // }`}

//                     onClick={() => {
//                       if (Login.token == null)
//                         navigate("/signin")
//                       else
//                         navigate("/userpage")
//                     }}
//                   >
//                     {/* Slot Title */}
//                     <div className="font-bold">{slot.title.rendered}</div>

//                     {/* Slot Price */}
//                     <div>Rs {slot.meta.price}</div>

//                     {/* Slot Availability */}
//                     <div>
//                       <span className="text-green-600 font-normal md:font-semibold">
//                         {slot.meta.availability}
//                       </span>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div className="text-center col-span-full text-gray-200">
//                   No slots available for this date.
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Booking;


// const SlotModalView = ({
//   DateBooking, setSelectedDate
// }) => {
//   const Login = useSelector((state) => state.LoginState);

//   const [reload, setReload] = React.useState(false)
//   const [Projects1, setProjects1] = React.useState([])
//   const [Orders, setOrder] = React.useState([])

//   const [SlotID, setSlotID] = React.useState("")
//   const [SlotType, setSlotType] = React.useState("")
//   const [SlotName, setSlotName] = React.useState("")
//   const [SlotModal, setSlotModal] = React.useState(false)
//   // const [DateBooking, setDateBooking] = React.useState(FetchData.meta.date_booking)
//   const [TimeBooking, setTimeBooking] = React.useState("")
//   const [Price, setPrice] = React.useState("")


//   const GetAllProjects = (params, slug) => {
//     setProjects1([])
//     AdsGETApi(params, slug)
//       .then((res) => {
//         setProjects1(res.data)
//       })
//       .catch(err => {
//       })
//   }
//   const GetAllNode = (params, slug) => {
//     setReload(false)
//     NodeGETApi(params, slug)
//       .then((res) => {
//         setOrder(res.data.orders)
//       })
//       .catch(err => {
//       }).finally(() => {
//         setReload(true)
//       })
//   }
//   useEffect(() => {
//     GetAllProjects({
//       per_page: 4900,
//       _fields: "id,slug,meta,title",
//       order: "asc"
//     }, slugs.db_slug_slots)
//     GetAllNode({
//       DateSelected: DateBooking
//     }, "token/date-order")

//   }, [])
//   return (
//     <div>
//       {Projects1.length !== 0 && reload &&
//         <div>
//           <div className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 overflow-y-scroll max-h-[370px] md:max-h-full mb-6"
//             style={{ marginTop: "10px" }}
//           >
//             {/* {DateBooking} */}
//             {Projects1.map((item, index) => (
//               <section key={index}
//                 className={` text-center shadow-sm cursor-pointer }`}
//               >
//                 <SlotBooking
//                   Orders={Orders} setSlotName={setSlotName}
//                   setPrice={setPrice} setTimeBooking={setTimeBooking}
//                   TimeBooking={TimeBooking}
//                   setSlotID={setSlotID} setSlotModal={setSlotModal}
//                   SlotID={SlotID} item={item}
//                   setSlotType={setSlotType} SlotType={SlotType}
//                   Projects1={Projects1}
//                   DateBooking={DateBooking}
//                 />
//               </section>
//             ))}
//           </div>
//           <button
//             onClick={handleBooking}
//             className="px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 disabled:bg-gray-500 disabled:cursor-not-allowed"
//             // disabled={!selectedSlot || slotsData[selectedDate]?.some((s) => s.time === selectedSlot?.time && s.booked)}
//             disabled={SlotID == ""}
//           >
//             Confirm Booking
//           </button>
//         </div>
//       }

//     </div>
//   )
// }


// const SlotBooking = ({
//   Orders, setSlotName, setPrice,
//   setTimeBooking, setSlotID, setSlotModal, SlotID, item,
//   setSlotType, DateBooking
// }) => {

//   const [Available, setAvailable] = React.useState(true)
//   const [ThisOrder, setThisOrder] = React.useState(false)
//   const [ThisBooked, setThisBooked] = React.useState("Available")
//   var searchOrder = Orders.filter(
//     a => a.meta.slot_id == item.id
//   )
//   useEffect(() => {
//     var searchOrder = Orders.filter(
//       a => a.meta.slot_id == item.id
//     )
//     if (searchOrder.length !== 0) {
//       setAvailable(false)
//       setThisBooked("Booked")
//       // console.log(searchOrder[0].id, ID, item.title.rendered)
//       // if (searchOrder[0].id == ID) {
//       //   setThisOrder(true)
//       // }
//     }
//     else {
//       // Orders
//       var dateOrder = Orders.filter(a => a.meta.date_booking == DateBooking)
//       var a = dateOrder.filter(a => a.meta.time == item.meta.time);
//       console.log(a, item.title.rendered)
//       if (a.length !== 0) {
//         var A7check = a.filter(a => a.meta.slot_type == "7A")
//         if (A7check.length !== 0) {
//           setAvailable(false)
//           setThisBooked("Unavailable")
//         }
//         var A5check = a.filter(a => a.meta.slot_type == "5A")
//         if (A5check.length !== 0 && item.meta.type == "7A") {
//           setAvailable(false)
//           setThisBooked("Unavailable")
//         }
//       }
//     }
//   }, [])
//   var SearchSuccess = () => {
//     setSlotName(item.title.rendered)
//     setPrice(item.meta.price)
//     setTimeBooking(item.meta.time)
//     setSlotType(item.meta.type)
//     setSlotID(item.id)
//     setSlotModal(false)
//   }
//   return (
//     <div
//       onClick={() => {
//         if (searchOrder.length == 0) {
//           SearchSuccess()
//         }
//         else {
//           if (ThisOrder) {
//             SearchSuccess()
//           }
//         }
//       }}
//     >
//       {DateBooking}
//       <div style={{
//         padding: 10, border: "1px solid #000", margin: 3, borderRadius: 10,
//         backgroundColor:
//           !Available ? ThisOrder ? "#8aeda9" : "#f00" :
//             (SlotID == item.id) ? "#fff" : "#8aeda9"
//       }}>
//         <div className="row">
//           <div className="col-8" style={{
//             color:
//               Available ? "#000" : ThisOrder ? "#000" : "#fff", fontWeight: "bold"
//           }}>
//             {item.title.rendered}
//           </div>
//           <div className="col-4" style={{ color: Available ? "#000" : ThisOrder ? "#000" : "#fff", fontWeight: "bold" }}>
//             {ThisBooked}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }





import React, { useState, useEffect } from "react";
import heroImage from "../../assets/img/background.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AdsGETApi, NodeGETApi } from "../../assets/api";
import { slugs } from "../../assets/slugs";

const Booking = () => {
  const Login = useSelector((state) => state.LoginState);
  const navigate = useNavigate();
  const [slotsData, setSlotsData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [message, setMessage] = useState("");
  const [reload, setReload] = React.useState(false)
  const [Orders, setOrders] = React.useState([])
  const [SlotsArray, setSlotsArray] = React.useState([])
  const [DateBooking, setDateBooking] = React.useState("")

  // Get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Fetch slots data from the API
  useEffect(() => {
    // axios
    //   .get("https://app.birtamodesportscenter.com/token/slots")
    //   .then((response) => {
    //     const currentDate = getCurrentDate();
    //     setSelectedDate(currentDate); // Set default selected date
    //     const formattedSlots = response.data.slots; // Extract the slots array
    //     setSlotsData(formattedSlots); // Store slots in state
    //   })
    //   .catch((error) => console.error("Error fetching slots:", error));
    GetAllSlots()
    GetAllOrders()
  }, []);

  const GetAllSlots = () => {
    AdsGETApi({
      per_page: 4900,
      _fields: "id,slug,meta,title"
    }, slugs.db_slug_slots)
      .then((res) => {
        setSlotsData(res.data)
        const today = new Date().toISOString().split("T")[0];
        setSelectedDate(today);
        setSlots(res.data[today] || []);
      })
      .catch(err => {
      })
  }
  const GetAllOrders = () => {
    setReload(false)
    NodeGETApi({ DateSelected: selectedDate }, "token/date-order")
      .then((res) => {
        setOrders(res.data.orders)
      })
      .catch(err => {
      }).finally(() => {
        setReload(true)
      })
  }

  // // Filter slots for the selected date
  // const filteredSlots = slotsData.filter(
  //   (slot) => slot.meta.date === selectedDate || slot.meta.date === "undefined"
  // );

  // // Handle date selection
  // const handleDateChange = (event) => {
  //   const date = event.target.value;
  //   setSelectedDate(date);
  // };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setDateBooking(date)
    setSelectedDate("");
    setTimeout(() => {
      setSelectedDate(date);

    }, 200)
    setSlots(slotsData[date] || []);
  };

  return (
    <div className="md:min-h-screen p-6 bg-gray-100">
      <div
        className="relative bg-cover h-[600px] md:h-[728px] text-white"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,41,83,1)0%] to-[rgba(0, 45, 91, 0.8) 80%]">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-4 text-center pt-16">
              Football Pitch Booking
            </h1>

            {/* Date Selector */}
            <div className="mb-6 text-center">
              <input
                type="date"
                value={selectedDate || ""}
                onChange={handleDateChange}
                min={new Date().toISOString().split("T")[0]} // Set minimum date to today
                max={new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]} // Set max date to 7 days from today
                className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-300 text-slate-500"
              />
            </div>

            {/* Selected Date */}
            {selectedDate && (
              <h2 className="md:text-xl text-sm md:font-semibold text-center md:mb-4">
                Selected Date: {selectedDate}
              </h2>
            )}

            {selectedDate !== "" &&
              <SlotModalView DateBooking={selectedDate} setSelectedDate={setSelectedDate} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;


const SlotModalView = ({
  DateBooking, setSelectedDate
}) => {
  const Login = useSelector((state) => state.LoginState);

  const [reload, setReload] = React.useState(false)
  const [Projects1, setProjects1] = React.useState([])
  const [Orders, setOrder] = React.useState([])

  const [SlotID, setSlotID] = React.useState("")
  const [SlotType, setSlotType] = React.useState("")
  const [SlotName, setSlotName] = React.useState("")
  const [SlotModal, setSlotModal] = React.useState(false)
  // const [DateBooking, setDateBooking] = React.useState(FetchData.meta.date_booking)
  const [TimeBooking, setTimeBooking] = React.useState("")
  const [Price, setPrice] = React.useState("")


  const GetAllProjects = (params, slug) => {
    setProjects1([])
    AdsGETApi(params, slug)
      .then((res) => {
        setProjects1(res.data)
      })
      .catch(err => {
      })
  }
  const GetAllNode = (params, slug) => {
    setReload(false)
    NodeGETApi(params, slug)
      .then((res) => {
        setOrder(res.data.orders)
      })
      .catch(err => {
      }).finally(() => {
        setReload(true)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 4900,
      _fields: "id,slug,meta,title",
      order: "asc"
    }, slugs.db_slug_slots)
    GetAllNode({
      DateSelected: DateBooking
    }, "token/date-order")

  }, [])
  return (
    <div>
      {Projects1.length !== 0 && reload &&
        <div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 overflow-y-scroll max-h-[370px] md:max-h-full mb-6"
            style={{ marginTop: "10px" }}
          >
            {/* {DateBooking} */}
            {Projects1.map((item, index) => (
              <section key={index}
                className={` text-center shadow-sm cursor-pointer }`}
              >
                <SlotBooking
                  Orders={Orders} setSlotName={setSlotName}
                  setPrice={setPrice} setTimeBooking={setTimeBooking}
                  TimeBooking={TimeBooking}
                  setSlotID={setSlotID} setSlotModal={setSlotModal}
                  SlotID={SlotID} item={item}
                  setSlotType={setSlotType} SlotType={SlotType}
                  Projects1={Projects1}
                  DateBooking={DateBooking}
                />
              </section>
            ))}
          </div>
          {/* <button
            onClick={handleBooking}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 disabled:bg-gray-500 disabled:cursor-not-allowed"
            // disabled={!selectedSlot || slotsData[selectedDate]?.some((s) => s.time === selectedSlot?.time && s.booked)}
            disabled={SlotID == ""}
          >
            Confirm Booking
          </button> */}
        </div>
      }

    </div>
  )
}


const SlotBooking = ({
  Orders, setSlotName, setPrice,
  setTimeBooking, setSlotID, setSlotModal, SlotID, item,
  setSlotType, DateBooking
}) => {
  const Login = useSelector((state) => state.LoginState);
  const navigate = useNavigate();

  const [Available, setAvailable] = React.useState(true)
  const [ThisOrder, setThisOrder] = React.useState(false)
  const [ThisBooked, setThisBooked] = React.useState("Available")
  var searchOrder = Orders.filter(
    a => a.meta.slot_id == item.id
  )
  useEffect(() => {
    var searchOrder = Orders.filter(
      a => a.meta.slot_id == item.id
    )
    if (searchOrder.length !== 0) {
      setAvailable(false)
      setThisBooked("Booked")
      // console.log(searchOrder[0].id, ID, item.title.rendered)
      // if (searchOrder[0].id == ID) {
      //   setThisOrder(true)
      // }
    }
    else {
      // Orders
      var dateOrder = Orders.filter(a => a.meta.date_booking == DateBooking)
      var a = dateOrder.filter(a => a.meta.time == item.meta.time);
      console.log(a, item.title.rendered)
      if (a.length !== 0) {
        var A7check = a.filter(a => a.meta.slot_type == "7A")
        if (A7check.length !== 0) {
          setAvailable(false)
          setThisBooked("Unavailable")
        }
        var A5check = a.filter(a => a.meta.slot_type == "5A")
        if (A5check.length !== 0 && item.meta.type == "7A") {
          setAvailable(false)
          setThisBooked("Unavailable")
        }
      }
    }
  }, [])
  var SearchSuccess = () => {
    setSlotName(item.title.rendered)
    setPrice(item.meta.price)
    setTimeBooking(item.meta.time)
    setSlotType(item.meta.type)
    setSlotID(item.id)
    setSlotModal(false)
  }
  return (
    <div
      onClick={() => {
        if (Login.token == null)
          navigate("/signin")
        else
          navigate("/userpage")
      }}
    >
      <div style={{
        padding: 10, border: "1px solid #000", margin: 3, borderRadius: 10,
        backgroundColor:
          !Available ? ThisOrder ? "#8aeda9" : "#f00" :
            (SlotID == item.id) ? "#fff" : "#8aeda9"
      }}>
        <div className="row">
          <div className="col-8" style={{
            color:
              Available ? "#000" : ThisOrder ? "#000" : "#fff", fontWeight: "bold"
          }}>
            {item.title.rendered}
          </div>
          <div className="col-4" style={{ color: Available ? "#000" : ThisOrder ? "#000" : "#fff", fontWeight: "bold" }}>
            {ThisBooked}
          </div>
        </div>
      </div>
    </div>
  )
}