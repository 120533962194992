import React from 'react';
import Dashboard from './Dashboard';

import MatchBooking from './Userbooking';

const Userpage = () => {
  return (
    <div>
        <Dashboard/>
    </div>
  )
}

export default Userpage