import React from 'react';

const Popup = ({ title, content, imageUrl, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl"
        >
          &times;
        </button>
        {/* Image */}
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Popup"
            className="w-full h-48 object-cover rounded-md mb-4"
          />
        )}
        {/* Title */}
        <h2 className="text-2xl font-bold mb-2">{title}</h2>
        {/* Content */}
        <p className="text-gray-700">{content}</p>
      </div>
    </div>
  );
};

export default Popup;
