// 
import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    phonenumber: '',
    email: '',
    message: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ fullName: '', phonenumber: '', email: '', message: '' });
  };

  return (
    <section className="text-center mt-20 shadow-inner">
      <div className="pt-16 mb-8">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
        <p className="text-gray-600 max-w-96 mx-auto px-2">
          If you have any further inquiries, please let us know by filling the form below.
        </p>
      </div>
      <form
        className="bg-white md:w-1/2 mx-auto p-6 rounded-md shadow-2xl space-y-4 mb-7"
        onSubmit={handleSubmit}
      >
        
          <div>
            <label htmlFor="fullName" className="block text-left font-medium text-sm mb-1">
              Full Name:
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              className="w-full border border-gray-500 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          
          <div>
            <label htmlFor="phonenumber" className="block text-left font-medium text-sm mb-1">
              Phone No.:
            </label>
            <input
              type="tel"
              id="phonenumber"
              name="phonenumber"
              value={formData.phonenumber}
              onChange={handleChange}
              required
              className="w-full border border-gray-500 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label htmlFor="address" className="block text-left font-medium text-sm mb-1">
              Address:
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              className="w-full border border-gray-500 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
        
        <div>
          <label htmlFor="email" className="block text-left font-medium text-sm mb-1">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full border border-gray-500 rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>
        <div>
          <label htmlFor="message" className="block text-left font-medium text-sm mb-1">
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full border border-gray-500 rounded-md px-3 py-2 h-24 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-400 focus:outline-none"
        >
          Submit
        </button>
      </form>
    </section>
  );
};

export default ContactForm;
